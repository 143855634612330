<template>
  <!--父元素使用relative，可以解决子元素使用absolute时，窗口大小改变元素乱跑问题，例如这里的.card-wrapper的background-img-->
  <el-card id="card-mark" style="position: relative;animation: article 1s;">
    <div class="ui" style="text-align: center;border-bottom: 1px solid #ebeef5;">
      <h2 class="m-text-500">文章归档</h2>
      <p>好! 目前共计 {{ count }} 篇日志。 继续努力。</p>
    </div>
    <div class="ui">
      <div class="timeline">
        <div v-for="(value,key,index) in blogMap" :key="index" :class="colorObj[index%5]">
          <div class="tl-header">
            <a class="ui year-wrapper">{{ key }}</a>
          </div>
          <div v-for="blog in value" :key="blog.id" class="tl-item">
            <div class="tl-wrap">
              <span class="tl-date">{{ blog.day }}</span>
              <router-link :to="`/blog/${blog.id}`">
                <div class="ui tl-title day-wrapper">{{ blog.title }}</div>
              </router-link>
            </div>
          </div>
        </div>
        <div class="tl-header">
          <a class="ui black large label m-text-500">Hello World!</a>
        </div>
      </div>
    </div>
    <div class="card-wrapper">
    </div>
  </el-card>
</template>

<script>

export default {
  name: "Archives",
  data() {
    return {
      blogMap: {},
      count: 0,
      colorObj: {
        0: 'tl-blue',
        1: 'tl-dark',
        2: 'tl-green',
        3: 'tl-purple',
        4: 'tl-red',
      }
    }
  },
  created() {
    this.getTimeline()
  },
  methods: {
    getTimeline() {
      this.$request.get("/timeline").then(res => {
        if (res.code === 200) {
          this.blogMap = res.data.blogMap
          this.count = res.data.count
        } else {
          this.msgError(res.msg);
        }
      }).then(() => {
        //根据el-card的高度设置背景纹理的高度
        let a = document.querySelector("#card-mark")
        let b = document.querySelector(".card-wrapper")
        b.style.height = a.clientHeight - 80 + "px"
        this.dayExtend()
      }).catch(() => {
        this.msgError("请求失败");
      })
    },
    dayExtend() {
      //解决右边界hover时因元素移动而失去hover的问题
      let dayEl = document.querySelectorAll(".day-wrapper")
      dayEl.forEach((el) => {
        el.addEventListener("mouseenter", () => {
          el.classList.add("extend")
        })
        el.addEventListener("mouseleave", () => {
          el.classList.remove("extend")
        })
      })
    }
  }
}
</script>

<style scoped>
.timeline {
  margin: 40px 0 0 20px;
}

.tl-header {
  width: 12em;
  text-align: center;
}

.tl-date {
  position: relative;
  top: 10px;
  display: block;
  float: left;
  width: 4.5em;
  margin-left: -7.5em;
  text-align: right;
}

.tl-wrap {
  padding: 15px 0 15px 20px;
  margin-left: 6em;
  border-style: solid;
  border-width: 0 0 0 4px;
}

.tl-wrap:before {
  position: relative;
  top: 14px;
  float: left;
  width: 16px;
  height: 16px;
  margin-left: -30px;
  background: #fff;
  border-radius: 50%;
  content: "";
  border-color: inherit;
  border-style: solid;
  border-width: 4px;
  transition: all 0.2s ease-in-out;
}

.tl-wrap:hover:before {
  border-color: #ffb997;
}

.tl-title {
  margin-left: 10px !important;
  letter-spacing: 0.3px !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  padding: 12px 15px !important;
  transition: all 0.2s ease-in-out;
}

.tl-title:hover {
  margin-left: 0 !important;
}

.tl-blue .tl-header a, .tl-blue .tl-item .tl-title {
  background: #23b7e5;
  color: #fff !important;
}

.tl-blue .tl-item .tl-wrap {
  border-color: #23b7e5;
}

.tl-dark .tl-header a, .tl-dark .tl-item .tl-title {
  background: #3a3f51;
  color: #fff !important;
}

.tl-dark .tl-item .tl-wrap {
  border-color: #3a3f51;
}

.tl-green .tl-header a, .tl-green .tl-item .tl-title {
  background: #27c24c;
  color: #fff !important;
}

.tl-green .tl-item .tl-wrap {
  border-color: #27c24c;
}

.tl-purple .tl-header a, .tl-purple .tl-item .tl-title {
  background: #7266ba;
  color: #fff !important;
}

.tl-purple .tl-item .tl-wrap {
  border-color: #7266ba;
}

.tl-red .tl-header a, .tl-red .tl-item .tl-title {
  background: #f05050;
  color: #fff !important;
}

.tl-red .tl-item .tl-wrap {
  border-color: #f05050;
}

.year-wrapper {
  display: inline-block;
  padding: 8px 11px;
  border-radius: 4px;
}

.day-wrapper {
  position: relative;
  display: inline-block;
  width: max-content;
  margin-top: 0;
  padding: 12px 15px;
  border-radius: 4px;
  /*不设置会被纹理背景挡住，hover无法触发*/
  z-index: 2;
}

.day-wrapper:hover {
  background-color: #ffb997 !important;
}

.day-wrapper:before {
  position: absolute;
  width: 9px;
  height: 9px;
  top: 50%;
  right: auto;
  bottom: auto;
  left: 0;
  content: '';
  background-color: inherit;
  transform: translateX(-50%) translateY(-50%) rotate(45deg);
}

.extend:after {
  position: absolute;
  width: 10px;
  height: 42px;
  background-color: rgba(255, 255, 255, 0);
  top: 0;
  right: auto;
  bottom: auto;
  left: 100%;
  content: '';
}

.card-wrapper {
  width: 245px;
  position: absolute;
  top: 7%;
  right: 20%;
  background: url("https://s1.ax1x.com/2022/09/20/xPkzwD.png") right top;
}

</style>